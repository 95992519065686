.dropdown-menu {
    position: fixed;
    top: 1%;
    left: 20px;
    z-index: 1000;
}

.dropdown-button {
    background-color: var(--navbar-background-color);
    color: var(--text-color);
    padding: 10px;
    font-size: 24px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    background-color: var(--navbar-background-color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 90vi;
}

.dropdown-content li {
    padding: 2%;
    text-align: left;
    font-size: 1.2em;
}

.dropdown-content li a {
    color: var(--text-color);
    text-decoration: none;
    display: block;
}

.dropdown-menu:hover .dropdown-content {
    display: block;
}

.sub-section {
    margin-left: 20px;
}