.plot-grades-container {
    width: var(--width-plot-grades-container);
    margin: auto;
    border: 1px solid var(--plot-grades-box-color);
    border-radius: 10px;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--notepad-background-color);
    padding: 10px;
    margin-top: 20px;
    font-family: var(--root-font-family);
}

.plot-grades-text {
    font-style: italic;
    color: var(--semesters-button-color);
    font-size: var(--font-size-plot-grades-text);
}

.plot-grades-title {
    text-align: center;
    color: var(--text-color);
    padding: 10px;
    background-color: var(--notepad-background-color);
    border-radius: 10px 10px 0 0;
}

.plot-grades {
    color: var(--text-color);
    width: 100%;
    height: 300px;
    font-family: var(--root-font-family);

}

.semester-button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    font-family: var(--root-font-family);
}

.semester-button {
    background-color: var(--semesters-button-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-family: var(--root-font-family);

}

.semester-button:hover {
    background-color: var(--button-hover-color);
    transform: scale(1.05);
}

.semester-button:active {
    background-color: #666;
}