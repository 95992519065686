.container {
  /* justify-content: center; */
  /* Horizontally centers the container */
  /* display: flex; */
  /* flex-flow: column wrap; */
  align-items: center;
  border: 2px solid var(--box-border-color);
  /* Darker border color */
  padding: 10px;
  margin-bottom: 2%;
  margin-top: 2%;
  border-radius: 20px;
  box-shadow: 1px var(--box-shadow-color);
  /* Slightly larger shadow */
  background-color: var(--notepad-background-color);
  /* Darker background color */
  width: var(--notepad-width);
  transition: ease-in-out, transform 0.3s ease-in-out;
  transform-style: preserve-3d;
  /* To preserve 3D perspective */
  position: relative;
  /* Relative positioning for the flip */
  font-family: var(--notepad-font-family);

}

.flipped {
  transform: rotateY(180deg);
}

.antiflipped {
  transform: rotateY(180deg);
  /* To Ensure that the inner content is not mirrored, we flip double (180 + 180 mod 360) = 0 -> no flip*/
}



.title-style {
  border: 2px solid var(--title-box-border-color);
  /* Darker border for title */
  border-radius: 10px;
  font-size: 1.2em;
  /* Slightly larger font size */
  font-weight: bold;
  margin-bottom: 3%;
  color: var(--text-color);
  /* Lighter text color */
  text-align: center;
  /* Center align text */
  width: 90%;
  /* Equal width to the container */
}

.hasButton {
  margin: 3%;
  margin-left: 2%;
  margin-right: 2%;
}


.hasNoButton {
  margin: 3%;
  font-size: 0.9em;
  padding-top: var(--notepad-title-margin-top-and-bottom)3%;
  padding-bottom: 3%;

}



.content-style {
  border: 2px dashed var(--content-box-border-color);

  /* Darker border for content */
  padding: 15px;
  border-radius: 10px;
  font-size: 0.6em;
  /* Larger font size */
  line-height: 1.6;
  color: var(--text-color);
  /* Lighter text color */
  text-align: left;
  /* Justify text */
  width: var(--width-notepad-content-style);
  /* Equal width to the container */
}



.button-container {
  display: flex;
  justify-content: space-between;
  /* Distribute items evenly along the row */
  align-items: center;
  /* Center items vertically */
  font-size: 0.5em;
  /* Adjust margin as needed */
  width: 100%;
  /* Width of the container */
}

.button-container .right-button {
  background-color: var(--notepad-background-color);
  /* Button background color */
  color: #ffffff;
  /* Button text color */
  margin: var(--margin-notepad-button);
  width: 5%;
  height: 5%;
  border: none;
  /* Remove border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  position: relative;
  /* Position relative for pseudo-element */
  animation: moveButtonRight 1s infinite alternate;
}

.button-container .right-button::after {
  content: '';
  /* Empty content for pseudo-element */
  position: absolute;
  /* Position absolute for positioning */
  top: 50%;
  /* Center vertically */
  right: var(--button-container-after-margin-left-or-right);
  /* Adjust position */
  transform: translateY(-50%) translateX(0);
  /* Center vertically and initial horizontal position */
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  /* Triangle height */
  border-bottom: 20px solid transparent;
  /* Triangle height */
  border-left: 30px solid var(--button-arrow-color);
  /* Triangle width and color */
  /* Animate to indicate interactivity */
}

@keyframes moveButtonRight {
  from {
    transform: translateY(-50%) translateX(0%);
  }

  to {
    transform: translateY(-50%) translateX(var(--translate-button-right));
  }
}

@keyframes moveButtonLeft {
  from {
    transform: translateY(-50%) translateX(0%);
  }

  to {
    transform: translateY(-50%) translateX(var(--translate-button-left));
  }
}

.with-padding {
  padding-top: 3%;
}

.button-container .left-button {
  background-color: var(--notepad-background-color);
  /* Button background color */
  color: #ffffff;
  /* Button text color */
  margin: var(--margin-notepad-button);
  width: 5%;
  height: 5%;
  border: none;
  /* Remove border */
  /* Padding inside the button */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  position: relative;
  /* Position relative for pseudo-element */
  animation: moveButtonLeft 1s infinite alternate;
  animation-delay: 0.5s;
}

.button-container .left-button::after {
  content: '';
  /* Empty content for pseudo-element */
  position: absolute;
  /* Position absolute for positioning */
  top: 50%;
  /* Adjust position */
  transform: translateY(-50%) rotate(0deg);
  /* Center vertically and rotate for left direction */
  left: var(--button-container-after-margin-left-or-right);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  /* Triangle height */
  border-bottom: 20px solid transparent;
  /* Triangle height */
  border-right: 30px solid var(--button-arrow-color);
  /* Triangle width and color */
}


li {
  margin: 2%;
}

.date-container {
  color: var(--text-color);
  font-size: 0.5em;
  margin-left: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-bottom: 0%;
}

a {
  color: var(--a-text-color)
}