header {
    text-align: center;
    /* Choose a serif font for a classic look */
    color: var(--text-color);
    /* Dark text color */
    background-color: inherit;
    /* Light gray background */
    padding: 20px;
    border-radius: 8px;
    /* Slightly rounded corners */
    box-shadow: 4px 4px 4px 4px var(--box-border-color);
    /* Subtle shadow */
    margin: 20px auto;
    max-width: var(--Heading-max-width);
    margin-top: 30vh;
    margin-bottom: var(--Heading-margin-bottom);
    /* Limit width for readability */
    text-align: left;

}

.header-content {
    background-color: inherit;

    font-size: var(--Heading-quote-font-size);
}

.author-container {
    margin: 2%;
    display: flex;
    font-size: var(--Heading-author-font-size);
    justify-content: flex-start;
}