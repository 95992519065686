.scroll-button {
    top: 20px;
    text-align: center;
    margin-bottom: var(--Heading-scroll-down-button-margin-bottom);
    background-color: none;

}

.scroll-button a {
    text-decoration: none;
    color: var(--semesters-button-color);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.arrow {
    width: 32px;
    height: 32px;
    border-bottom: 2px solid var(--semesters-button-color);
    border-right: 2px solid var(--semesters-button-color);
    transform: rotate(45deg);
    margin: 5px 0;
    animation: bounce 2s infinite;
}

.scroll-button button {
    background-color: inherit;
    border: 0px;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) rotate(45deg);
    }

    40% {
        transform: translateY(10px) rotate(45deg);
    }

    60% {
        transform: translateY(5px) rotate(45deg);
    }
}