.project-container {
    padding: 2%;
    font-family: var(--root-font-family);

}

.work-notepad-content {
    margin-top: 3%;
    /* Adjust the gap between columns */
    /* Add padding to the whole content area */
    background-color: var(--notepad-background-color);
    /* Relative positioning for the flip */
    font-family: var(--notepad-font-family);
    /* Use your desired font family */
    /* Darker border for content */
    font-size: 0.5em;
    /* Larger font size */
    color: var(--text-color);
    /* Lighter text color */
    /* Justify text */
    /* Equal width to the container */
    height: 40%;
    padding: 2%;
}

.content-about-project {
    font-size: var(--font-size-outside-notepad-box);
    line-height: 1.6;

    /* Take up 2 parts of the available space */
    /* Optional: Add top margin */
    /* font-size: 19.2px; */

    /* Optional: Adjust font size */
    color: var(--text-color);
    /* Optional: Adjust text color */
    /* Optional: Justify text */
}

.separation {
    border-top: 3px dashed var(--box-border-color);
}

.skill-set-and-description {
    line-height: var(--line-height);

    /* Adjust the gap between columns */
    /* Add padding to the whole content area */
    background-color: var(--notepad-background-color);
    /* Relative positioning for the flip */
    font-family: var(--notepad-font-family);
    /* Use your desired font family */
    /* Darker border for content */
    border-radius: 10px;
    font-size: 0.5em;
    /* Larger font size */
    color: var(--text-color);
    /* Lighter text color */
    /* Justify text */
    /* Equal width to the container */
    text-align: left;
}


.work-notepad-content table {
    width: 100%;
}

.work-notepad-content td {
    white-space: nowrap;
}

.github-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: var(--semesters-button-color);
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
}

.github-button:hover {
    background-color: #555;
    /* Darker shade on hover */
}

.content-about-project img {
    width: 100%;
}

.content-about-project .dark-theme .invert-for-black {
    filter: var(--invert-filter-for-black)
}

.content-about-project .DLA-simulation-class {
    content: var(--path-DLA);
    height: auto;
    display: block;
}

.content-about-project .random-particle-simulation-class {
    content: var(--path-Random);
    height: auto;
    display: block;
}

.content-about-project .invert-for-gray {
    filter: var(--invert-filter-for-gray)
}

.content-about-project .particle-simulation {
    font-size: var(--particle-simulation-font-size)
}

.content-about-project .invert-for-white {
    filter: var(--invert-filter-for-white)
}

.content-about-project figcaption {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
}

.visualTable {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.visualTable th,
.visualTable td {
    border: 1px solid var(--course-table-border-color);
    text-align: start;
    padding: 8px;
}

.visualTable th {
    background-color: --var(--notepad-background-color);
    color: var(--text-color);
    font-weight: bold;
}

.visualTable tr:hover {
    background-color: var(--box-border-color);
}

.visualTable caption {
    caption-side: bottom;
    text-align: left;
    padding-top: 10px;
    font-size: 14px;
    color: var(--text-color);
}