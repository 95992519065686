.contact-form {
  width: 90%;
  background-color: var(--contact-form-background-color);
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  font-family: var(--root-font-family);
}

.contact-form label {
  color: var(--text-color);
}

.contact-form input,
.contact-form textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--box-border-color);
  border-radius: 5px;
  background-color: var(--contact-form-field-background-color);
  color: var(--text-color);
}

.contact-form textarea {
  resize: vertical;
}

.contact-form button {
  background-color: var(--contact-form-button-color);
  color: #FFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: var(--button-hover-color);
}


.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #333;
  font-family: var(--notepad-font-family)
}

.error-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #dc3545;
  /* Bootstrap's danger color */
}

.error-container p {
  font-size: 1.2rem;
  text-align: center;
  line-height: var(--line-height);
  color: #6c757d;
  /* Bootstrap's secondary color */
}


.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--contact-form-background-color);
  font-family: var(--notepad-font-family);
}

.success-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #28a745;
  /* Bootstrap's success color */
}

.success-container p {
  font-size: 1.2rem;
  text-align: center;
  line-height: var(--line-height);
  color: #6c757d;
  /* Bootstrap's secondary color */
}

.error-image {
  color: #a72828;
  /* Bootstrap's success color */
}

.success-image {
  width: 30%;
}