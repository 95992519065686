.app-body {
  /* background-image: url('../src/assets/images/backgroundImage.jpg');
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: var(--webpage-background-color);

  background-attachment: fixed;
  /* Keep background fixed */
  color: #fff;
  /* Enable vertical scrolling for content */
  height: 100vh;
  /* Full viewport height */
  margin: 0;
  padding: 0;
  font-family: var(--notepad-font-family);

}

.app-body .fade-in {
  opacity: 0;
  /* Start with opacity 0 (fully transparent) */
  transition: opacity 3s ease-in-out;
  /* Transition opacity over 0.5 seconds */
}

.app-body .visible {
  opacity: 1;
  /* Change opacity to 1 (fully opaque) when .visible class is applied */
}